import React from 'react';
import { Text } from '@fluentui/react/lib/Text';
import { Icon } from '@fluentui/react/lib/Icon';
import { mergeStyleSets, keyframes } from '@fluentui/react/lib/Styling';

const fadeInScale = keyframes({
  from: { opacity: 0, transform: 'scale(0.9)' },
  to: { opacity: 1, transform: 'scale(1)' }
});

const styles = mergeStyleSets({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    animation: `${fadeInScale} 0.5s ease-out`,
  },
  icon: {
    fontSize: '64px',
    color: '#0078d4',
    marginBottom: '20px',
  },
  title: {
    fontSize: '36px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  message: {
    fontSize: '24px',
    textAlign: 'center',
  },
});

const SuccessScreen = () => {
  return (
    <div className={styles.container}>
      <Icon iconName="CheckMark" className={styles.icon} />
      <Text className={styles.title}>Success</Text>
      <Text className={styles.message}>
        Welcome to Global Secure Access
      </Text>
    </div>
  );
};

export default SuccessScreen;