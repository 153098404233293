import React, { useState, useEffect } from 'react';
import { Text } from '@fluentui/react/lib/Text';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { Panel } from '@fluentui/react/lib/Panel';
import { mergeStyleSets, keyframes } from '@fluentui/react/lib/Styling';
import { Image } from '@fluentui/react/lib/Image';
import SuccessScreen from './SuccessScreen';

const fadeInSlideUp = keyframes({
  from: { opacity: 0, transform: 'translateY(20px)' },
  to: { opacity: 1, transform: 'translateY(0)' }
});

const fadeOutSlideDown = keyframes({
  from: { opacity: 1, transform: 'translateY(0)' },
  to: { opacity: 0, transform: 'translateY(20px)' }
});

const spin = keyframes({
  from: { transform: 'rotate(0deg)' },
  to: { transform: 'rotate(360deg)' }
});

const styles = mergeStyleSets({
  container: {
    height: '100%',
    padding: '20px',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'hidden',
  },
  stepIndicator: {
    fontWeight: 'bold',
    fontSize: '24px',
    marginBottom: '20px',
    transition: 'opacity 0.5s ease-out, transform 0.5s ease-out',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    transition: 'opacity 0.5s ease-out, transform 0.5s ease-out',
  },
  listContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    marginBottom: '20px',
  },
  list: {
    width: '48%',
    backgroundColor: 'white',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    overflow: 'auto',
  },
  header: {
    padding: '16px',
    borderBottom: '1px solid #e0e0e0',
    backgroundColor: '#ffffff',
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  headerText: {
    fontSize: '22px',
    fontWeight: '700',
  },
  appItem: {
    margin: '10px',
    padding: '15px',
    backgroundColor: 'white',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  appName: {
    fontSize: '16px',
    fontWeight: '500',
  },
  addButton: {
    backgroundColor: '#0078d4',
    color: 'white',
    borderRadius: '50%',
    width: '32px',
    height: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    border: 'none',
    cursor: 'pointer',
    padding: 0,
    lineHeight: '1',
  },
  plusIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    marginTop: '-2px',
  },
  migrateButton: {
    alignSelf: 'center',
  },
});

const AppItem = ({ app, onExpand, index }) => (
  <div 
    className={styles.appItem} 
    style={{
      animation: `${fadeInSlideUp} 0.5s ease-out ${index * 0.1}s both`,
    }}
  >
    <Text className={styles.appName}>{app.name}</Text>
    <button className={styles.addButton} onClick={() => onExpand(app)}>
      <span className={styles.plusIcon}>+</span>
    </button>
  </div>
);

const LoadingAnimation = ({ text }) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      animation: `${fadeInSlideUp} 0.5s ease-out`,
    }}>
      <Image 
        src="/logo192.png" 
        alt="React Logo"
        width={100}
        height={100}
        style={{
          animation: `${spin} 2s linear infinite`,
        }}
      />
      <Text 
        variant="xxLarge"
        style={{ 
          marginTop: 20,
          fontWeight: 'bold',
        }}
      >
        {text}
      </Text>
    </div>
  );
};

const AppMigrationList = () => {
  const [netskope, setNetskope] = useState([
    { id: 1, name: 'Example App 1', details: 'Details for Example App 1' },
    { id: 2, name: 'Example App 2', details: 'Details for Example App 2' },
    { id: 3, name: 'Example App 3', details: 'Details for Example App 3' },
  ]);
  const [gsa, setGsa] = useState([
    { id: 1, name: 'newPrivateApp 1', details: 'Details for newPrivateApp 1' },
    { id: 2, name: 'newPrivateApp 2', details: 'Details for newPrivateApp 2' },
    { id: 3, name: 'newPrivateApp 3', details: 'Details for newPrivateApp 3' },
  ]);
  const [selectedApp, setSelectedApp] = useState(null);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [isMigrating, setIsMigrating] = useState(false);
  const [currentStep, setCurrentStep] = useState(4);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleExpand = (app) => {
    setSelectedApp(app);
    setIsPanelOpen(true);
  };

  const closePanel = () => {
    setIsPanelOpen(false);
  };

  const handleMigrate = () => {
    setIsMigrating(true);
    setTimeout(() => {
      setCurrentStep(5);
    }, 500);
  };

  useEffect(() => {
    if (isMigrating) {
      const timer = setTimeout(() => {
        setIsMigrating(false);
        setShowSuccess(true);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [isMigrating]);

  if (showSuccess) {
    return <SuccessScreen />;
  }

  return (
    <div className={styles.container}>
      <div 
        className={styles.stepIndicator} 
        style={{
          opacity: isMigrating ? 0 : 1,
          transform: isMigrating ? 'translateY(20px)' : 'translateY(0)',
        }}
      >
        Step {currentStep}:
      </div>
      {isMigrating ? (
        <LoadingAnimation text="Transferring to GSA" />
      ) : (
        <div 
          className={styles.content}
          style={{
            opacity: isMigrating ? 0 : 1,
            transform: isMigrating ? 'translateY(20px)' : 'translateY(0)',
          }}
        >
          <div className={styles.listContainer}>
            <div className={styles.list}>
              <div className={styles.header}>
                <Text className={styles.headerText}>Netskope</Text>
              </div>
              {netskope.map((app, index) => (
                <AppItem key={app.id} app={app} onExpand={handleExpand} index={index} />
              ))}
            </div>
            <div className={styles.list}>
              <div className={styles.header}>
                <Text className={styles.headerText}>Global Secure Access</Text>
              </div>
              {gsa.map((app, index) => (
                <AppItem key={app.id} app={app} onExpand={handleExpand} index={index} />
              ))}
            </div>
          </div>
          <PrimaryButton 
            text="Migrate Apps" 
            className={styles.migrateButton}
            onClick={handleMigrate}
            styles={{
              root: {
                animation: `${fadeInSlideUp} 0.5s ease-out 0.5s both`,
              }
            }}
          />
        </div>
      )}
      <Panel
        isOpen={isPanelOpen}
        onDismiss={closePanel}
        headerText={selectedApp ? selectedApp.name : ''}
        closeButtonAriaLabel="Close"
        isLightDismiss
      >
        <p>{selectedApp ? selectedApp.details : ''}</p>
      </Panel>
    </div>
  );
};

export default AppMigrationList;